<template>
  <section id="hero">
    <v-img
      :height="$vuetify.breakpoint.mdAndUp ? 250 : 150"
      :min-height="minHeight"
      src="/images/stock/homepage.jpg"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
    >
      <v-container class="fill-height px-4 py-8">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          min-height="450"
          max-width="700"
          width="50%"
        >
          <base-heading
            title="We Shop Lenders Because You Have Better Things To Do."
            small-size="display-0"
            mobile-size="display-0"
          />

          <base-body>
            Integrity, Service & Transparent lending.
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <!-- <base-btn>
              Discover More
            </base-btn> -->

            <h2>1 (405) 406-9222</h2>

            <!-- <base-btn
              :ripple="false"
              class="pa-1"
              height="auto"
              text
            >
              Get Started Now
            </base-btn> -->
          </div>
        </v-responsive>
        <v-responsive
          class="d-flex align-center"
          height="100%"
          min-height="450"
          width="50%"
        >
          <div class="flex-column align-right">
            <base-news-card
              v-if="newsLoaded"
              align="center"
              dark
              v-bind="topNews"
            />
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  import * as api from '@/functions/api.js'

  export default {
    name: 'SectionHero',
    data: () => ({
      news: [],
      topNews: {},
      newsLoaded: false,
    }),
    provide: {
      theme: { isDark: true },
    },
    computed: {
      minHeight () {
        // const height = this.$vuetify.breakpoint.mdAndUp ? '75vh' : '50vh'
        const height = this.$vuetify.breakpoint.smAndUp ? '450px' : '300px'
        // console.log(`calc(${height} - ${this.$vuetify.application.top}px)`)
        // return `calc(${height} - ${this.$vuetify.application.top}px)`
        return height
      },
    },
    mounted () {
      // console.log('PORTAL_ACTIVE', process.env.PORTAL_ACTIVE)
      // this.initialize()
    },
    methods: {
      initialize () {
        api.getAll('news/active/1/0').then(results => {
          // console.log(results.data)
          this.news = results.data
          if (this.news.results.length === 1) {
            this.topNews = this.news.results[0]
            this.newsLoaded = true
            // console.log(this.topNews)
          }
          // break up the news into slides
          // var LastSlideCount = this.news.total % this.pageSize
          // var slideCount = parseInt(this.news.total / this.pageSize)
          // for (var i = 0; i < slideCount; ++i) {
          //   this.slides.push(this.news.results.slice(i * this.pageSize, (i + 1) * this.pageSize))
          // }
          // // Now, push the remainder
          // if (LastSlideCount > 0) {
          //   this.slides.push(this.news.results.slice(slideCount * this.pageSize))
          // }
          // // console.log(this.slides);
          // this.newsLoaded = true
        })
      },
    },
  }
</script>
